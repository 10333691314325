import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllTransactions } from "../../state/appSlice";
import { convertToCurrency } from "../../utils/functions";

const TotalSold = () => {
  let num = 0;
  const totalMoney = useSelector((state) => state.app.totalMoney);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllTransactions()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="Sales">
      <h2>Total Sold</h2>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        <p>{convertToCurrency(totalMoney)}</p>
      )}
    </div>
  );
};

export default TotalSold;
