import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import LatestSale from "./LatestSale";
import Variations from "./Variations";
import TotalSold from "./TotalSold";

const Sales = () => {
  let match = useRouteMatch();

  return (
    <div className="Sales">
      <h1>Sales</h1>
      <nav>
        <ul>
          <li>
            <Link to={`${match.url}/latest-sale`}>Latest Sale</Link>
          </li>
          <li>
            <Link to={`${match.url}/variations`}>Variations</Link>
          </li>
          <li>
            <Link to={`${match.url}/total-sales`}>Total Sold</Link>
          </li>
        </ul>
      </nav>

      <Switch>
        <Route path={`${match.path}/latest-sale`}>
          <LatestSale />
        </Route>
        <Route path={`${match.path}/variations`}>
          <Variations />
        </Route>
        <Route path={`${match.path}/total-sales`}>
          <TotalSold />
        </Route>
      </Switch>
    </div>
  );
};

export default Sales;
