import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { enterNewStock } from "../../state/appSlice";
import "./EnterStock.css";

const cleanItem = {
  category: "",
  name: "",
  size: "",
  colour: "",
  link: "",
  stock: "",
  supplier: "",
  image: "",
};

const EnterStock = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({ ...cleanItem });

  const handleItem = (field, value) => {
    let tempItem = item;
    tempItem = {
      ...tempItem,
      [field]: value,
    };
    setItem(tempItem);
  };

  return (
    <div className="EnterStock">
      <h2>Enter stock</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            dispatch(enterNewStock(item)).then(() => {
              setLoading(false);
              setItem({ ...cleanItem });
            });
          }}
        >
          <span>Category</span>
          <select
            name="category"
            id="category"
            onChange={(e) => handleItem("category", e.target.value)}
          >
            <option value="">--- Select ---</option>
            <option value="Frames">Frames</option>
            <option value="Packaging">Packaging</option>
            <option value="Papeleria">Papeleria</option>
            <option value="Inks">Inks</option>
          </select>
          <span>Name</span>
          <input
            type="text"
            value={item.name}
            onChange={(e) => handleItem("name", e.target.value)}
          />
          <span>Size</span>
          <input
            type="text"
            value={item.size}
            onChange={(e) => handleItem("size", e.target.value)}
          />
          <span>Colour</span>
          <input
            type="text"
            value={item.colour}
            onChange={(e) => handleItem("colour", e.target.value)}
          />
          <span>Link</span>
          <input
            type="text"
            value={item.link}
            onChange={(e) => handleItem("link", e.target.value)}
          />
          <span>Stock</span>
          <input
            type="text"
            value={item.stock}
            onChange={(e) => handleItem("stock", e.target.value)}
          />
          <span>Supplier</span>
          <input
            type="text"
            value={item.supplier}
            onChange={(e) => handleItem("supplier", e.target.value)}
          />
          <span>Image</span>
          <input
            type="text"
            value={item.image}
            onChange={(e) => handleItem("image", e.target.value)}
          />
          <br />
          <br />
          <button>Submit</button>
        </form>
      )}
    </div>
  );
};

export default EnterStock;
