import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAutoSales } from "../../state/appSlice";
import { ellipsify } from "../../utils/functions";
import "./AutoSales.css";

const AutoSales = () => {
  const autoSales = useSelector((state) => state.app.autoSales);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAutoSales()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="AutoSales">
      <h2>Auto Sales</h2>
      <ul>
        <li>Todos los cuadros con frame ponen 'Frame'?</li>
        <li>
          Si un cliente compra 2 con frame se puede identificar esta cantidad en
          la data de la API? (ir a Etsy - Single Sale to check)
        </li>
        <li>Que es 'White Frame Set'?</li>
      </ul>
      <div className="header">
        <span>Date</span>
        <span>Title</span>
        <span>Item</span>
        <span>Materials</span>
        <span className="price">Price</span>
      </div>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        autoSales.length > 0 &&
        autoSales.map((item) => (
          <div className="body" key={item._id}>
            <p>{item.etsyDate}</p>
            <p>{ellipsify(item.title, 40)}</p>
            <p>{item.item}</p>
            <p>{item.materials}</p>
            <p className="price">£{item.price}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default AutoSales;
