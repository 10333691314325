export const url =
  "https://openapi.etsy.com/v2/shops/25265739/transactions?oauth_consumer_key=7choywsx70d5wmcxfjdq40ii&oauth_token=24c24b8afa0561f64d7423c5c0d1f9&oauth_signature_method=PLAINTEXT&oauth_timestamp=1618871195&oauth_nonce=iUB3Q1b3sfs&oauth_version=1.0&oauth_signature=nnhothw0yz%266eb4c59984";

export const corsUrl =
  "https://cors-anywhere.herokuapp.com/https://openapi.etsy.com/v2/shops/25265739/transactions?oauth_consumer_key=7choywsx70d5wmcxfjdq40ii&oauth_token=24c24b8afa0561f64d7423c5c0d1f9&oauth_signature_method=PLAINTEXT&oauth_timestamp=1618871195&oauth_nonce=iUB3Q1b3sfs&oauth_version=1.0&oauth_signature=nnhothw0yz%266eb4c59984";

//FIXME: USE THESE FOR NOW
export const localUrl = "https://server-25portal.herokuapp.com";
// export const localUrl = "http://192.168.1.48:5000";
// export const localUrl = "https://chipped-forest-actor.glitch.me";
