import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { corsUrl, localUrl } from "../utils/urls";

const initialState = {
  sales: [],
  autoSales: [],
  stock: [],
  stockDefaultOrder: [],
  totalSales: 0,
  totalMoney: 0,
  transactions: [],
  isLogged: JSON.parse(localStorage.getItem("isLogged")),
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSales: (state, action) => {
      state.sales = action.payload.reverse();
    },
    setAutoSales: (state, action) => {
      state.autoSales = action.payload.reverse();
    },
    setStock: (state, action) => {
      state.stock = action.payload;
      state.stockDefaultOrder = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setTotalSales: (state, action) => {
      state.totalSales = action.payload;
    },
    setTotalMoney: (state, action) => {
      state.totalMoney = action.payload;
    },
    setLogin: (state, action) => {
      state.isLogged = action.payload;
      localStorage.setItem("isLogged", state.isLogged);
    },
    logout: (state) => {
      state.isLogged = false;
      localStorage.setItem("isLogged", false);
    },
    sortStock: (state, action) => {
      if (action.payload === "stock") {
        state.stock.sort((a, b) => a.stock - b.stock);
      } else {
        state.stock = state.stockDefaultOrder;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSales,
  setAutoSales,
  setStock,
  setTotalMoney,
  setTotalSales,
  setLogin,
  sortStock,
  logout,
  setTransactions,
} = appSlice.actions;

export const login = (password) => async (dispatch) => {
  try {
    const user = await axios.post(`${localUrl}/api/v1/login`, { password });
    dispatch(setLogin(user.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchTransactions = () => async (dispatch) => {
  try {
    const data = await fetch(`${localUrl}/api/v1/transactions`);
    const res = await data.json();
    dispatch(setTransactions(res.data.results));
    dispatch(setTotalSales(res.data.count));
  } catch (err) {
    console.log(err);
  }
};

//cors anywhere: https://github.com/Rob--W/cors-anywhere#demo-server
export const fetchAllTransactions = () => async (dispatch) => {
  try {
    const data = await fetch(`${localUrl}/api/v1/transactions/all`);
    const res = await data.json();
    dispatch(setTotalMoney(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const enterNewSale = (item) => async () => {
  try {
    await axios.post(`${localUrl}/api/v1/sales`, { ...item });
  } catch (err) {
    console.log(err);
  }
};

export const fetchSales = () => async (dispatch) => {
  try {
    const data = await fetch(`${localUrl}/api/v1/sales`);
    const res = await data.json();
    dispatch(setSales(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchAutoSales = () => async (dispatch) => {
  try {
    const data = await fetch(`${localUrl}/api/v1/sales/auto`);
    const res = await data.json();
    dispatch(setAutoSales(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const addMaterial = (item) => async () => {
  try {
    await axios.patch(`${localUrl}/api/v1/stock`, { ...item });
  } catch (err) {
    console.log(err);
  }
};

export const enterDailyStock =
  ({ date, items }) =>
  async () => {
    try {
      await axios.post(`${localUrl}/api/v1/stock/daily`, { date, items });
    } catch (err) {
      console.log(err);
    }
  };

export const enterNewStock = (item) => async () => {
  try {
    await axios.post(`${localUrl}/api/v1/stock`, { ...item });
  } catch (err) {
    console.log(err);
  }
};

export const fetchStock = () => async (dispatch) => {
  try {
    const data = await fetch(`${localUrl}/api/v1/stock`);
    const res = await data.json();
    dispatch(setStock(res.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchTransactionsHeroku = () => async (dispatch) => {
  try {
    const data = await fetch(corsUrl);
    const res = await data.json();
    dispatch(setSales(res.results));
  } catch (err) {
    console.log(err);
  }
};

export default appSlice.reducer;
