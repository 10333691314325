import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactions } from "../../state/appSlice";
import { flattenObj } from "../../utils/functions";
import "./LatestSale.css";

const LatestSale = () => {
  const transactions = useSelector((state) => state.app.transactions);
  const totalSales = useSelector((state) => state.app.totalSales);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getSales();
  }, []);

  const getSales = async () => {
    dispatch(fetchTransactions()).then(() => {
      setLoading(false);
    });
  };

  const renderValue = (value) => {
    if (typeof value == "boolean") {
      return value ? "Yes" : "No";
    } else if (value > 1000000000 && value < 1800000000) {
      //FIXME: values hardcoded (after 2027 won't work)
      return new Date(value * 1000).toDateString();
    }
    return value;
  };

  return (
    <div className="LatestSale">
      <h2>Latest Sale</h2>
      <p>Total ventas: {totalSales}</p>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        transactions.length > 0 &&
        transactions.slice(0, 1).map((sale) =>
          Object.entries(flattenObj(sale)).map(([key, value]) => (
            <div key={key}>
              <p className="label">{key}</p>
              <p>{renderValue(value)}</p>
            </div>
          ))
        )
      )}
    </div>
  );
};

export default LatestSale;
