import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import AllStock from "./AllStock";
import DailyStock from "./DailyStock";
import EnterStock from "./EnterStock";
import "./Stock.css";

const Stock = () => {
  let match = useRouteMatch();

  return (
    <div className="Stock">
      <h1>Stock</h1>
      <nav>
        <ul>
          <li className="dev-use">
            <Link to={`${match.url}/daily`}>Daily Stock</Link>
          </li>
          <li>
            <Link to={`${match.url}/all-stock`}>All Stock</Link>
          </li>
          <li>
            <Link to={`${match.url}/enter`}>Enter Stock</Link>
          </li>
        </ul>
      </nav>

      <Switch>
        <Route path={`${match.path}/daily`}>
          <DailyStock />
        </Route>
        <Route path={`${match.path}/all-stock`}>
          <AllStock />
        </Route>
        <Route path={`${match.path}/enter`}>
          <EnterStock />
        </Route>
      </Switch>
    </div>
  );
};

export default Stock;
