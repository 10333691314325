import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import AllSales from "./AllSales";
import AutoSales from "./AutoSales";
import EnterSale from "./EnterSale";
import "./Sales.css";

const Sales = () => {
  let match = useRouteMatch();

  return (
    <div className="Sales">
      <h1>Sales</h1>
      <nav>
        <ul>
          <li>
            <Link to={`${match.url}/all-sales`}>All Sales</Link>
          </li>
          <li>
            <Link to={`${match.url}/auto-sales`}>Auto Sales</Link>
          </li>
          <li>
            <Link to={`${match.url}/enter`}>Enter Sale</Link>
          </li>
        </ul>
      </nav>

      <Switch>
        <Route path={`${match.path}/all-sales`}>
          <AllSales />
        </Route>
        <Route path={`${match.path}/auto-sales`}>
          <AutoSales />
        </Route>
        <Route path={`${match.path}/enter`}>
          <EnterSale />
        </Route>
      </Switch>
    </div>
  );
};

export default Sales;
