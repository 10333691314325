import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStock, enterNewSale } from "../../state/appSlice";
import "./EnterSale.css";

const cleanItem = {
  date: new Date(),
  item: "",
  price: "",
  material: "",
  quantity: "",
};

const EnterSale = () => {
  const stockItems = useSelector((state) => state.app.stock);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [materials, setMaterials] = useState([]);
  const [item, setItem] = useState({ ...cleanItem });
  const dispatch = useDispatch();

  const handleItem = (field, value) => {
    let tempItem = item;
    tempItem = {
      ...tempItem,
      [field]: field === "date" ? new Date(value) : value,
    };
    setItem(tempItem);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchStock()).then(() => {
      setLoading(false);
    });
  }, []);

  const addMaterial = (e) => {
    e.preventDefault();
    let newMaterials = materials;
    newMaterials.push({ material: item.material, quantity: item.quantity });
    setMaterials(newMaterials);
    // setItem(item);
  };

  return (
    <div className="EnterSale">
      <h2>Enter sale</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <span className="options">
            <p
              className={`${type === "Framed" ? "selected" : ""}`}
              onClick={() => setType("Framed")}
            >
              Framed
            </p>
            <p
              className={`${type === "Print" ? "selected" : ""}`}
              onClick={() => setType("Print")}
            >
              Print
            </p>
            <p
              className={`${type === "Digital" ? "selected" : ""}`}
              onClick={() => setType("Digital")}
            >
              Digital
            </p>
          </span>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              //FIXME: NO MANUAL ENTER SALES FOR NOW
              // dispatch(enterNewSale(item)).then(() => {
              //   setLoading(false);
              //   setItem({ ...cleanItem });
              // });
            }}
          >
            <div>
              <span>Date</span>
              <input
                type="date"
                value={item.date.toISOString().substring(0, 10)}
                onChange={(e) => handleItem("date", e.target.value)}
              />
              <span>Item</span>
              <input
                type="text"
                value={item.item}
                onChange={(e) => handleItem("item", e.target.value)}
              />
              <span>Price</span>
              <input
                type="text"
                value={item.price}
                onChange={(e) => handleItem("price", e.target.value)}
              />
              <br />
              <br />
              <br />
              <button>Submit</button>
            </div>
            <div className="common-materials">
              <span className="common-title">Common Materials</span>
              {type === "Framed" ? (
                <div>
                  <p>x1 paper A4</p>
                  <p>x1 box</p>
                </div>
              ) : type === "Print" ? (
                <div>
                  <p>x1 paper A4</p>
                  <p>x1 thank you paper</p>
                </div>
              ) : (
                type === "Digital" && (
                  <div>
                    <p>No common materials</p>
                  </div>
                )
              )}
              <div>
                {materials.map((mat, i) => (
                  <p key={i}>
                    x{mat.quantity} {mat.material}
                  </p>
                ))}
              </div>
              <span>Material</span>
              <select
                name="select-item"
                id="select-item"
                onChange={(e) => handleItem("material", e.target.value)}
              >
                <option value="">--- Select ---</option>
                {stockItems.map((stockItem) => (
                  <option key={stockItem._id} value={stockItem.name}>
                    {stockItem.name}
                  </option>
                ))}
              </select>
              <span>Quantity</span>
              <input
                type="text"
                value={item.quantity}
                onChange={(e) => handleItem("quantity", e.target.value)}
              />
              <br />
              <button onClick={addMaterial}>Add</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EnterSale;
