import Home from "./pages/Home";
import Sales from "./pages/Sales/Sales";
import Stock from "./pages/Stock/Stock";
import Expenses from "./pages/Expenses/Expenses";
import Etsy from "./pages/Etsy/Etsy";
import { useDispatch } from "react-redux";
import { logout } from "./state/appSlice";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";

function App() {
  const dispatch = useDispatch();

  return (
    <Router>
      <div className="App">
        <span
          className="logout"
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </span>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="dev-use">
              <Link to="/stock/daily">Stock</Link>
            </li>
            <li>
              <Link to="/sales/all-sales">Sales</Link>
            </li>
            <li>
              <Link to="/expenses/all-expenses">Expenses</Link>
            </li>
            <li>
              <Link to="/etsy/latest-sale">Etsy</Link>
            </li>
          </ul>
        </nav>
        <Switch>
          <Route path="/etsy">
            <Etsy />
          </Route>
          <Route path="/stock">
            <Stock />
          </Route>
          <Route path="/expenses">
            <Expenses />
          </Route>
          <Route path="/sales">
            <Sales />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
