import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import AllExpenses from "./AllExpenses";
import EnterExpense from "./EnterExpense";

const Expenses = () => {
  let match = useRouteMatch();

  return (
    <div className="Expenses">
      <h1>Expenses</h1>
      <nav>
        <ul>
          <li>
            <Link to={`${match.url}/all-expenses`}>All Expenses</Link>
          </li>
          <li>
            <Link to={`${match.url}/enter`}>Enter Expenses</Link>
          </li>
        </ul>
      </nav>

      <Switch>
        <Route path={`${match.path}/all-expenses`}>
          <AllExpenses />
        </Route>
        <Route path={`${match.path}/enter`}>
          <EnterExpense />
        </Route>
      </Switch>
    </div>
  );
};

export default Expenses;
