import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addMaterial } from "../../state/appSlice";
import "./EnterExpense.css";

const cleanItem = {
  material: "",
  quantity: "",
};

const EnterExpense = () => {
  const stockItems = useSelector((state) => state.app.stock);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({ ...cleanItem });
  const dispatch = useDispatch();

  const handleItem = (field, value) => {
    let tempItem = item;
    tempItem = {
      ...tempItem,
      [field]: value,
    };
    setItem(tempItem);
  };

  return (
    <div className="EnterExpense">
      <h2>Enter Expense</h2>
      <div className="dashboard">
        <h3>Add Items</h3>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              //FIXME: NO MANUAL ENTER EXPENSES FOR NOW
              // dispatch(addMaterial(item)).then(() => {
              //   setLoading(false);
              //   setItem({ ...cleanItem });
              // });
            }}
          >
            <select
              name="select-item"
              id="select-item"
              onChange={(e) => handleItem("material", e.target.value)}
            >
              <option value="">--- Select ---</option>
              {stockItems.map((item) => (
                <option key={item._id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
            <br />
            <br />
            <input
              type="text"
              placeholder="Quantity"
              value={item.quantity}
              onChange={(e) => handleItem("quantity", e.target.value)}
            />
            <br />
            <br />
            <button>Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default EnterExpense;
