import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "./state/appSlice";
import App from "./App";
import "./Web.css";

const Web = () => {
  const isLogged = useSelector((state) => state.app.isLogged);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const checkLogin = () => {
    dispatch(login(password));
    setPassword("");
  };

  return (
    <div>
      {isLogged ? (
        <App />
      ) : (
        <div className="Web">
          <div>
            <h2>TwentyFiveStore Socios</h2>
            <input
              type="password"
              placeholder="Password"
              className="enter-site"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button onClick={checkLogin}>Entrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Web;
