import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactions } from "../state/appSlice";
import "./Home.css";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const totalSales = useSelector((state) => state.app.totalSales);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTransactions()).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="Home">
      <h1>Home</h1>
      <div className="dashboard">
        <h3>TwentyFiveStore</h3>
        {loading ? (
          <p className="loading">Loading...</p>
        ) : (
          <div>
            <p>Total Sales: {totalSales}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
