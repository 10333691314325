import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStock, enterDailyStock } from "../../state/appSlice";
import { v4 as uuidv4 } from "uuid";
import "./DailyStock.css";

const cleanItems = {
  Frames: [
    {
      id: 1,
      name: "",
      quantity: 1,
    },
  ],
  Packaging: [
    {
      id: 1,
      name: "",
      quantity: 1,
    },
  ],
  Papeleria: [],
  Inks: [],
};

const DailyStock = () => {
  const stock = useSelector((state) => state.app.stock);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [items, setItems] = useState(cleanItems);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchStock()).then(() => {
      setLoading(false);
    });
  }, []);

  const addNewItem = (category) => {
    let tempItems = items;
    tempItems = {
      ...tempItems,
      [category]: [
        ...tempItems[category],
        {
          id: uuidv4(),
          name: "",
          quantity: 1,
        },
      ],
    };
    setItems(tempItems);
  };

  const deleteItem = (id, category) => {
    setItems({
      ...items,
      [category]: items[category].filter((item) => item.id !== id),
    });
  };

  const handleItem = (id, field, category, value) => {
    let tempItems = items;
    tempItems = {
      ...tempItems,
      [category]: tempItems[category].map((el) => {
        if (el.id === id) {
          if (field === "name") {
            return { ...el, name: value };
          }
          return { ...el, quantity: value };
        }
        return { ...el };
      }),
    };
    setItems(tempItems);
  };

  const enterStock = (e) => {
    e.preventDefault();
    let updateItems = {};
    Object.entries(items).forEach(([key, value]) => {
      if (value.length > 0) {
        updateItems[key] = value;
      }
    });
    setLoading(true);
    dispatch(enterDailyStock({ date, items: updateItems })).then(() => {
      setLoading(false);
      setItems({ ...cleanItems });
    });
  };

  return (
    <div className="DailyStock">
      <h2>Daily Stock</h2>
      <input
        type="date"
        value={date.toISOString().substring(0, 10)}
        onChange={(e) => setDate(new Date(e.target.value))}
      />
      <br />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <form onSubmit={enterStock}>
            {Object.entries(items).map(([key, value]) => (
              <div key={key}>
                <h4>{key}</h4>
                {value.map((item) => (
                  <div className="row" key={item.id}>
                    <select
                      name="select-item"
                      id="select-item"
                      onChange={(e) =>
                        handleItem(item.id, "name", key, e.target.value)
                      }
                    >
                      <option defaultValue>--- Select ---</option>
                      {stock.map(
                        (stockItem) =>
                          stockItem.category === key && (
                            <option key={stockItem._id} value={stockItem.name}>
                              {stockItem.name}
                            </option>
                          )
                      )}
                    </select>
                    <input
                      className="quantity"
                      type="number"
                      placeholder="Quantity"
                      value={item.quantity}
                      step={item.name === "Box" ? "0.5" : "any"}
                      onChange={(e) =>
                        handleItem(item.id, "quantity", key, e.target.value)
                      }
                    />
                    <img
                      src="/images/delete.png"
                      alt="Trash can"
                      onClick={() => deleteItem(item.id, key)}
                    />
                  </div>
                ))}
                <span className="add" onClick={() => addNewItem(key)}>
                  Add item
                </span>
              </div>
            ))}
            <p className="date">{date.toDateString()}</p>
            <button className="send">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default DailyStock;
