import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransactions } from "../../state/appSlice";
import { flattenObj } from "../../utils/functions";
import "./Variations.css";

const Variations = () => {
  const transactions = useSelector((state) => state.app.transactions);
  const [loading, setLoading] = useState(true);
  const exclusiveValues = [
    "title",
    "price",
    "variations_0_formatted_value",
    "product_data_property_values_0_property_name",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getSales();
  }, []);

  const getSales = async () => {
    dispatch(fetchTransactions()).then(() => {
      setLoading(false);
    });
  };

  return (
    <div className="Variations">
      <h2>Variation value from API</h2>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        transactions.length > 0 &&
        transactions.slice(0, 50).map((sale) =>
          Object.entries(flattenObj(sale)).map(
            ([key, value]) =>
              key === "variations_0_formatted_value" && (
                <div key={key}>
                  <p className="label">{key}</p>
                  <p>{value}</p>
                </div>
              )
          )
        )
      )}
    </div>
  );
};

export default Variations;
