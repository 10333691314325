import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ellipsify } from "../../utils/functions";
import { fetchStock, sortStock } from "../../state/appSlice";
import "./AllStock.css";

const AllStock = () => {
  const stock = useSelector((state) => state.app.stock);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("item");
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchStock()).then(() => {
      setLoading(false);
    });
  }, []);

  const checkStock = (stock) => {
    if (stock <= 5) {
      return "five-items";
    } else if (stock <= 10) {
      return "ten-items";
    } else if (stock > 100) {
      return "hundred-items";
    }
  };

  const sortBy = (sort) => {
    setOrder(sort);
    dispatch(sortStock(sort));
  };

  return (
    <div className="AllStock">
      <h2>All Stock</h2>
      <span>Sorted by:</span>
      <span className="options">
        <p
          className={`${order === "item" ? "selected" : ""}`}
          onClick={() => sortBy("item")}
        >
          Item
        </p>
        <p
          className={`${order === "stock" ? "selected" : ""}`}
          onClick={() => sortBy("stock")}
        >
          Stock
        </p>
      </span>
      <div className="header">
        <span>Item</span>
        <span className="stock">Stock</span>
        <span>Link</span>
        <span>Supplier</span>
      </div>
      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        stock.length > 0 &&
        stock.map((item) => (
          <div className="body" key={item._id}>
            <p>{item.name}</p>
            <p className={`stock ${checkStock(item.stock)}`}>{item.stock}</p>
            <p>{ellipsify(item.link, 20)}</p>
            <p>{item.supplier}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default AllStock;
